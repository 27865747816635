import { getExcelFileJsonDataFromAWS } from '../util/api';

// actionTypes.js
export const FETCH_DATA_REQUEST = 'app/category/FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'app/category/FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'app/category/FETCH_DATA_FAILURE';

// actions.js
export const fetchDataRequest = () => ({
  type: FETCH_DATA_REQUEST,
});

export const fetchDataSuccess = data => ({
  type: FETCH_DATA_SUCCESS,
  payload: data,
});

export const fetchDataFailure = error => ({
  type: FETCH_DATA_FAILURE,
  payload: error,
});

// reducer.js
const initialState = {
  data: [],
  loading: false,
  error: null,
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...action.payload],
      };
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;

// Thunk action to fetch data
export const fetchJsonData = (page, pageSize) => async dispatch => {
  dispatch(fetchDataRequest());
  try {
    const res = await getExcelFileJsonDataFromAWS({ pageSize, page });
    dispatch(fetchDataSuccess(res));
  } catch (error) {
    dispatch(fetchDataFailure(error.message));
  }
};
