// categoryPageActions.js
export const SET_LOCATION_DATA = 'app/singleLocation/SET_LOCATION_DATA';

export const setLocationData = locationData => ({
  type: SET_LOCATION_DATA,
  payload: locationData,
});

const initialState = {
  locationData: null,
};

const categoryPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOCATION_DATA:
      return {
        ...state,
        locationData: action.payload,
      };
    default:
      return state;
  }
};

export default categoryPageReducer;
